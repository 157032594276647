@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Content {
}

.contentInnerWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: px(16);
  justify-content: flex-start;
  padding-top: px(16);
}

.description,
.subtitle,
.descriptionString {
  @include font-body;

  p {
    margin-bottom: px(15);
  }
}

.descriptionString {
  white-space: pre-line;
}
