@import "@/styles/shared";
.Header {
  display: flex;
}

.title {
  @include font-sh3;
  display: flex;
  flex-grow: 1;
}

.titleOpenState {
  color: var(--primary-color);
}

.icon {
  height: px(24);
  width: px(24);
}

.iconWrapper {
  padding-top: px(8);
  transform: rotate(00deg);
  transition: transform 0.3s ease-in-out;
}

.iconWrapperOpenState {
  transform: rotate(180deg);
}

.dropdownButton {
  @include reset-button;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  opacity: 0.6;
  transition: opacity 0.6s ease-in-out;
}

.dropdownButtonOpenState {
  opacity: 1;
}
