@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.NavigationButtons {
  transition: opacity 0.2s;
  gap: px(8);
  display: flex;

  @include bp(laptop) {
    opacity: 1;
    pointer-events: all;
    @include flex-center;
  }

  &.hidden {
    @include bp(laptop) {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.orderDropdown {
  [data-button-label] {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100vw - calc(var(--page-gutter) * 5));

    @include bp(laptop) {
      max-width: px(189);
    }
  }
}
