@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.RestaurantNavigation {
  @include z-index(resaurant-navigation);
  color: var(--text-color);
  background-color: var(--bg-color);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid rgba(var(--fg-rgb), 0.1);
  padding: px(8) var(--page-gutter);
  transform: translateY(105%);

  @include bp(tablet) {
    padding: px(16) var(--page-gutter);
  }
}

.inner {
  max-width: var(--section-max-width);
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @include bp(tablet) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.leftSide {
  width: 100%;
}

.blurbContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include bp(tablet) {
    justify-content: flex-start;
  }
}

.title {
  @include font-body-xl-display;
  margin-top: px(8);
}

.rightSide {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  width: 100%;
  gap: px(8);
  margin-top: px(16);

  @include bp(mobile) {
    @include flex-center;
    justify-content: flex-start;
  }

  @include bp(tablet) {
    margin-top: 0;
    gap: px(16);
    justify-content: flex-end;
  }

  .reserveButton,
  .orderDropdown,
  [data-button],
  [data-link] {
    @include bp(mobile, true) {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.setAsPreferredMobile {
  display: block;

  @include bp(tablet) {
    display: none;
  }
}

.setAsPreferredDesktop {
  display: none;

  @include bp(tablet) {
    display: block;
  }

  @include bp(laptop) {
    margin-right: px(30);
  }
}

.setAsPreferredContainer {
  @include flex-center;
  gap: px(6);
  user-select: none;
}

.checkboxInput {
  @include reset-button;
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.setAsPreferred__label {
  @include font-button;
  cursor: pointer;
  white-space: nowrap;

  .checkboxInput:focus + & {
    outline: 1px solid var(--azurite);
  }
}

.checkboxBox {
  @include box(px(20));
  @include flex-center;
  border-radius: 50%;
  opacity: 0.2;
  position: relative;
  display: block;
  border: 1px solid var(--text-color);
  cursor: pointer;

  .checkboxInput:checked ~ & {
    opacity: 1;
  }
}

.checkboxBox__circle {
  @include position-100(absolute);
  border-radius: 50%;
  background-color: var(--text-color);
  opacity: 0;

  .checkboxInput:checked ~ .checkboxBox & {
    opacity: 1;
  }
}

.checkboxBox__check {
  opacity: 0;
  position: relative;
  z-index: 2;
  color: var(--bg-color);
  width: px(12);
  transform: translate(#{px(3)}, #{px(-1)});

  .checkboxInput:checked ~ .checkboxBox & {
    opacity: 1;
  }
}
