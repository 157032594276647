@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MerchandiseSlideout {
  @include z-index(merch-slide-out);
  @include position-100(fixed);
  @include dark-theme;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  color: var(--text-color);
  overflow: hidden;
}

.bg {
  @include position-100(absolute);
  background-color: var(--bg-color);
  z-index: 1;
  opacity: 0;
}

.inner {
  background-color: var(--bg-color);
  position: absolute;
  width: 100%;
  z-index: 2;
  height: 100svh;
  overflow: hidden;
  max-width: px(1024);
  transform: translateX(105%);
}

.content {
  @include hide-scrollbar;
  max-height: 100svh;
  overflow: auto;
  padding: var(--section-spacing) var(--page-gutter) px(60);
  gap: px(47);
  display: flex;
  flex-direction: column;

  @include bp(tablet) {
    flex-direction: row;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: px(40);
  }
}

.textSide {
  order: 2;

  @include bp(tablet) {
    order: 1;
  }
}

.closeButton {
  @include reset-button;
  @include flex-center;
  border: 1px solid rgba(var(--fg-rgb), 0.6);
  background-color: var(--bg-color);
  width: var(--circle-button-dimensions);
  height: var(--circle-button-dimensions);
  border-radius: 50%;
  position: absolute;
  top: px(24);
  right: var(--page-gutter);
  z-index: 4;
}

.closeIcon {
  width: px(24);
}

.title {
  @include font-sh1;
  margin-bottom: px(16);
}

.price {
  @include font-body;
  margin-bottom: px(16);
}

.description {
  @include font-body;
}

.accordion {
  margin-top: px(56);
  margin-bottom: px(24);

  @include bp(tablet) {
    margin-top: px(88);
  }

  [data-accordion-title] {
    @include font-body-xl-display;
  }

  [data-accordion-icon-container] svg {
    width: px(20);
  }

  [data-accordion-item] {
    padding-bottom: px(18);
    margin-bottom: px(18);
  }
}

.availabilityDescription {
  @include font-detail-s;
}

.imagesSide {
  order: 1;

  @include bp(tablet) {
    order: 2;
  }
}

.activeImageContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1/1;
}

.activeImage {
  @include position-100(absolute);
  object-fit: cover;
}

.imagesList {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: px(13);
  margin-top: px(21);
}

.imagesList__item {
  opacity: 0.4;

  &.isActive {
    opacity: 1;
    outline: 1px solid var(--wine);
  }
}

.imagesList__itemButton {
  @include reset-button;
  aspect-ratio: 1/1;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}

.imagesList__image {
  @include position-100(absolute);
  object-fit: cover;
}

.focusFix {
  @include reset-button;
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
}
