@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ContentWithStickyNavigationMobileMenu {
  @include z-index(content-with-sticky-navigation-mobile);
  left: 0;
  width: 100%;
  height: var(--content-with-sticky-nav-button-height);
  @include bp(xsmobile, true) {
    height: calc(var(--content-with-sticky-nav-button-height-xsmobile) + #{px(624)});
  }

  &[data-scrolling-state='start'] {
    position: absolute;
    top: var(--offset-top);
  }

  &[data-scrolling-state='end'] {
    position: absolute;
    top: var(--offset-bottom);
  }

  &[data-scrolling-state='fixed'] {
    position: fixed;
    top: var(--header-height);
  }

  &[data-scrolling-state='fixed'].stickyBannerIsOpen {
    top: calc(var(--header-height) + var(--stickybanner-height));
    @include bp(xsmobile, true) {
      top: calc(var(--header-height) + var(--stickybanner-height-xsmobile));
    }
  }

  @each $colour in $background-colours {
    &[data-theme='#{$colour}'] {
      @if $colour == black or $colour == burgundy {
        @include dark-theme;
      } @else {
        @include light-theme;
      }
    }
  }
}

.headerButton {
  @include reset-button;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--content-with-sticky-nav-button-height);
  padding: 0 var(--page-gutter);
  background-color: var(--background-color);
  border-top: 1px solid rgba(var(--fg-rgb), 0.1);
  border-bottom: 1px solid rgba(var(--fg-rgb), 0.1);
  z-index: 2;

  --icon-width: px(16);
}

.headerButton__text {
  @include font-button;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - var(--icon-width));
}

.headerButton__icon {
  width: px(16);
  transition: transform $transition-short;

  .isOpen & {
    transform: rotate(-180deg);
  }
}

.content {
  --combined-header-heights: calc(var(--content-with-sticky-nav-button-height) + var(--header-height));
  --mobile-content-height: calc(100vh - var(--combined-header-heights));

  z-index: 1;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  height: var(--mobile-content-height);
  position: absolute;
  top: var(--content-with-sticky-nav-button-height);
  left: 0;
  width: 100%;
  transform: translateY(#{px(-24)});
  transition: opacity $transition-short, transform $transition-short;

  .isOpen & {
    transform: none;
    opacity: 1;
    pointer-events: all;
  }
}

.contentInner {
  padding: px(40) var(--page-gutter);
  max-height: var(--mobile-content-height);
  position: relative;
  overflow: auto;
  background-color: var(--background-color);
  border-bottom: 1px solid rgba(var(--fg-rgb), 0.1);
  padding-bottom: px(80);
}

.linkList {
  display: flex;
  flex-direction: column;
  gap: px(12);
}

.linkList__itemButton {
  @include reset-button;
  @include font-detail;
  border-bottom: 1px solid transparent;
  line-height: 1.1;

  .isActive & {
    border-color: var(--text-color);
  }
}
