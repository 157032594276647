@import "@/styles/shared";
.StickyBanner {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 var(--page-gutter);
  margin: 0 -40px;
  height: var(--stickybanner-height);

  @include bp(xsmobile, true) {
    height: var(--stickybanner-height-xsmobile);
  }
}

.closeButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: px(10);
  background: none;
  border: none;
  padding: px(5);
  z-index: 2;
}

.closeIcon {
  width: px(24);
  right: px(24);
  color: var(--white);
  margin-right: px(10);
}

.hide {
  display: none;
}

.Content {
  @include font-body-s;
  text-align: center;
  width: 100%;
  padding: px(6) px(30);
  color: var(--white);
  z-index: 1;

  @include bp(laptop) {
    padding: px(14);
  }
}

.backgroundImage {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}
