@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.NavigationMobileDropdown {
  @include z-index(navigation-mobile);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  overflow: hidden;
  color: var(--text-color);
  opacity: 0;
  transform: translateY(#{px(-24)});
  pointer-events: none;
  transition: transform $transition-short, opacity $transition-short;

  &.navIsOpen {
    @include bp(laptop, true) {
      opacity: 1;
      transform: none;
      pointer-events: all;
    }
  }

  @each $colour in $background-colours {
    &[data-theme='#{$colour}'] {
      @if $colour == black or $colour == burgundy {
        @include dark-theme;
      } @else {
        @include light-theme;
      }
    }
  }
}

.inner {
  @include hide-scrollbar;
  display: flex;
  flex-direction: column;
  max-height: 100svh;
  height: 100svh;
  overflow: auto;
  padding: px(16) var(--page-gutter);
}

.linksAndButtons {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  gap: px(38);
}

.linkItem {
  @include font-sh1;
  transition: color $transition-short;
}

.linkItemActive {
  opacity: 0.5;
}

.socialMediaAndLegal {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: px(8);
}

.socialMediaLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: px(24);
}

.socialMediaLinks__link {
  @include font-body;
  transition: color $transition-short;
}

.legalContainer {
  @include font-body-s;
  opacity: 0.5;
  transition: color $transition-short;
}
