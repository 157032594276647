@import "@/styles/shared";
.Accordion {
  margin-bottom: px(48);
}

.item {
  border-bottom: 1px solid rgba(var(--fg-rgb), 0.1);
  overflow: hidden;
  padding-bottom: px(24);
  margin-bottom: px(24);
}

.contentWrapper {
  height: 0;
  overflow: hidden;
}

.contentWrapperOpen {
  height: auto;
}
