@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.OrderDropdown {
  width: min-content;
  position: relative;

  [data-icon='caretDown'] {
    transition: transform $transition-short;
  }

  &.isFocused {
    [data-icon='caretDown'] {
      transform: rotate(-180deg);
    }
  }
}

.dropdown {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  overflow: auto;
  z-index: 2;
  background-color: var(--bg-color);
  color: var(--button-secondary-label-color);
  border-color: var(--button-secondary-outline-color);
  border-radius: px(24);
  border-width: px(1);
  border-style: solid;
  max-height: px(166);
  min-width: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  [data-dropdown-position='bottomLeft'] & {
    top: calc(100% + 2px);
    left: 0;
  }

  [data-dropdown-position='bottomRight'] & {
    top: calc(100% + 2px);
    right: 0;
  }

  [data-dropdown-position='topLeft'] & {
    bottom: calc(100% + 2px);
    left: 0;
  }

  [data-dropdown-position='topRight'] & {
    bottom: calc(100% + 2px);
    right: 0;
  }

  .isFocused & {
    opacity: 1;
    pointer-events: all;
  }
}

.dropdown__inner {
  padding: px(24);
  display: flex;
  flex-direction: column;
  gap: px(11);
  min-width: 100%;
}

.dropdownLink,
.customButton {
  @include reset-button;
  @include font-detail-s;
  white-space: nowrap;
}
