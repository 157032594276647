@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.VideoPopout {
  @include z-index(video-popout);
  @include position-100(fixed);
  @include flex-center;
}

.bg {
  @include position-100(absolute);
  z-index: 1;
  background-color: $black;
  opacity: 0.6;
}

.videoContainer {
  width: calc(100% - calc(var(--page-gutter) * 2));
  max-width: px(1300);
  position: relative;
  z-index: 2;

  @include bp(tablet) {
    width: calc(100% - calc(var(--page-gutter) * 4));
  }

  @media (max-height: 830px) {
    max-width: px(1000);
  }
}

.embedContainer {
  position: relative;
  aspect-ratio: 100/56.25;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
