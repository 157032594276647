@import "@/styles/shared";
.Button {
  @include reset-button;
  @include button-colors;
  @include button-transition-values;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--button-padding);
  border-radius: px(50);
  height: var(--button-height);
  border-width: px(1);
  border-style: solid;

  &[data-button-type='plain'] {
    padding: 0;
    color: var(--text-color);
    height: auto;
  }

  // &.hover {
  //   [data-filled] {
  //     opacity: 1;
  //   }
  // }

  &[data-button-type='primary'] {
    color: var(--button-primary-label-color);
    border-color: var(--button-primary-outline-color);
    background-color: var(--button-primary-background-color);

    &.hover {
      color: var(--button-primary-label-hover-color);
      border-color: var(--button-primary-outline-hover-color);
      background-color: var(--button-primary-background-hover-color);
    }
  }

  &[data-button-type='secondary'] {
    color: var(--button-secondary-label-color);
    border-color: var(--button-secondary-outline-color);
    background-color: var(--button-secondary-background-color);

    &.hover {
      color: var(--button-secondary-label-hover-color);
      border-color: var(--button-secondary-outline-hover-color);
      background-color: var(--button-secondary-background-hover-color);
    }
  }

  &[data-button-type='tertiary'] {
    color: var(--button-tertiary-label-color);
    border-color: var(--button-tertiary-outline-color);
    background-color: var(--button-tertiary-background-color);

    &.hover {
      color: var(--button-tertiary-label-hover-color);
      border-color: var(--button-tertiary-outline-hover-color);
      background-color: var(--button-tertiary-background-hover-color);
    }
  }
}

.label {
  @include font-button;
  white-space: nowrap;
}

.link {
  display: inline-block;
}

.icon {
  @include flex-center;
  margin-left: px(16);
  display: grid;
  grid-template-areas: 'a';

  [data-button-type='plain'] & {
    margin-left: px(5);
  }

  svg {
    width: px(20);
    grid-area: a;
    transition: opacity #{$transition-short};
    z-index: 2;

    // &[data-filled] {
    //   position: relative;
    //   z-index: 1;
    //   opacity: 0;
    // }
  }

  [data-button-type='primary'] & {
    color: var(--button-primary-label-color);
  }

  [data-button-type='primary'].hover & {
    color: var(--button-primary-label-hover-color);
  }

  [data-button-type='secondary'] & {
    color: var(--button-secondary-label-color);
  }

  [data-button-type='secondary'].hover & {
    color: var(--button-secondary-label-hover-color);
  }

  [data-button-type='tertiary'] & {
    color: var(--button-tertiary-label-color);
  }

  [data-button-type='tertiary'].hover & {
    color: var(--button-tertiary-label-hover-color);
  }
}
