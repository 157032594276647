@import "@/styles/shared";
.scrollContainer {
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.scrollContainerInner {
  display: block;
}
